interface ObservedElement extends HTMLElement {
  observer: IntersectionObserver
}

export default defineNuxtPlugin( ( nuxtApp ) => {
  nuxtApp.vueApp.directive( "fadeIn", {
    created ( el: ObservedElement ) {
      el.observer = new IntersectionObserver( entries => {
        // Loop over the entries
        entries.forEach( entry => {
          // If the element is visible
          if ( entry.isIntersecting ) {
            // Add the animation class
            const target = entry.target as HTMLElement
            target.style.setProperty( "opacity", "1" )
          }
        } )
      } )
    },
    mounted ( el: ObservedElement, binding: { value?: { duration?: number, delay?: number } } ) {
      const duration = binding.value?.duration ?? 0.6
      const delay = binding.value?.delay ?? 0.5
      el.style.opacity = "0"
      el.style.transitionProperty = "opacity"
      el.style.transitionTimingFunction = "ease"
      el.style.transitionDuration = `${duration * 1000}ms`
      el.style.transitionDelay = `${delay * 1000}ms`
      el.observer.observe( el )
    },
    beforeUnmount( el: ObservedElement ) {
      el.observer.unobserve( el )
    }
  } )
} )
