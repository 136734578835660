<script setup lang="ts">
  useHead( {
    title: "Clifton Bootcamp",
    meta: [
      { name: "description", content: "Outdoor Fitness Bootcamp in Clifton, Bristol" }
    ],
    link: [
      {
        rel: "icon",
        type: "image/png",
        href: "/images/cbc-icon-text.png"
      },
      {
        rel: "stylesheet",
        href: "https://rsms.me/inter/inter.css",
        crossorigin: "anonymous",
        async: true
      },
    ],
    htmlAttrs: {
      class: "h-full"
    },
    bodyAttrs: {
      class: "h-full bg-black"
    },
  } )
</script>

<template>
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
</template>
