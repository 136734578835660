import LogRocket from "logrocket"
import * as Sentry from "@sentry/nuxt"

export default defineNuxtPlugin( () => {
  const opts = useRuntimeConfig()?.public?.logRocket

  if ( !opts?.id || ( !opts?.dev && !( process.env.NODE_ENV === "production" ) ) )
    return

  LogRocket.init( opts?.id, opts?.config )

  LogRocket.getSessionURL( sessionURL => {
    Sentry.setContext( "logRocket", {
      sessionURL
    } )
  } )

  return { provide: { LogRocket } }
} )
