<template>
  <NuxtLayout>
    <PageSection class="min-h-[200px] md:min-h-[320px]">
      <div class="px-16 py-16 md:py-40">
        <template v-if="error.statusCode === 404">
          <h1 class="text-3xl md:text-5xl font-bold px-8 pb-6">
            Error 404!
          </h1>
          <p>Sorry, that page doesn't exist.</p>
        </template>
        <template v-else>
          <h1 class="text-3xl md:text-5xl font-bold px-8 pb-6">
            Ooops!
          </h1>
          <h2 class="text-xl md:text-2xl font-bold px-8 pt-2 pb-6">
            {{ error.message }}
          </h2>
          <p>It looks like something broke.</p>
          <p>Sorry about that.</p>
        </template>
        <p class="pt-4">
          Pick an option from the header above, or go back to the
          <a
            class="cursor-pointer underline"
            @click="handleError"
          >
            homepage.
          </a>
        </p>
      </div>
    </PageSection>
  </NuxtLayout>
</template>

<script setup lang="ts">
  const error = useError()

  const handleError = () => {
    clearError( {
      redirect: "/",
    } )
  }
</script>
