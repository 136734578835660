export function useComponentVisibility( id: string ) {
  const visibilityMap = useState( "component-visibility-map", () => ( {} as Record<string, boolean> ) )

  function showComponent() {
    visibilityMap.value[id] = true
  }

  function hideComponent() {
    visibilityMap.value[id] = false
  }

  function toggleComponent() {
    visibilityMap.value[id] = !visibilityMap.value[id]
  }

  function isVisible() {
    return visibilityMap.value[id] ?? false
  }

  return {
    isVisible,
    showComponent,
    hideComponent,
    toggleComponent,
  }
}
