import revive_payload_client_4sVQNw7RlN from "/home/web2wire/clifton-bootcamp/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/home/web2wire/clifton-bootcamp/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/home/web2wire/clifton-bootcamp/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_MwZUzHrRNP from "/home/web2wire/clifton-bootcamp/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_yVLowv6hDl from "/home/web2wire/clifton-bootcamp/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/home/web2wire/clifton-bootcamp/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/home/web2wire/clifton-bootcamp/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/home/web2wire/clifton-bootcamp/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/home/web2wire/clifton-bootcamp/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/home/web2wire/clifton-bootcamp/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_UYiXMU8ZyN from "/home/web2wire/clifton-bootcamp/node_modules/nuxt-gtag/dist/runtime/plugin.client.js";
import sentry_client_VdOeuw25Mb from "/home/web2wire/clifton-bootcamp/node_modules/@sentry/nuxt/build/module/runtime/plugins/sentry.client.js";
import sentry_client_config_o34nk2sJbg from "/home/web2wire/clifton-bootcamp/.nuxt/sentry-client-config.mjs";
import primevue_plugin_egKpok8Auk from "/home/web2wire/clifton-bootcamp/.nuxt/primevue-plugin.mjs";
import plugin_client_XxbS7tGyhp from "/home/web2wire/clifton-bootcamp/node_modules/@primevue/nuxt-module/dist/runtime/plugin.client.js";
import titles_dw2T9lEw4h from "/home/web2wire/clifton-bootcamp/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/titles.js";
import siteConfig_izaWKZ8rEu from "/home/web2wire/clifton-bootcamp/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/siteConfig.js";
import inferSeoMetaPlugin_Uk9bcXDHeN from "/home/web2wire/clifton-bootcamp/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/inferSeoMetaPlugin.js";
import _00_logRocket_client_yE2ocTW3Tj from "/home/web2wire/clifton-bootcamp/plugins/00.logRocket.client.ts";
import fadeIn_client_KC7KVKNaZA from "/home/web2wire/clifton-bootcamp/plugins/fadeIn.client.ts";
import globalComponents_client_lPWLC3zPcW from "/home/web2wire/clifton-bootcamp/plugins/globalComponents.client.ts";
import openTrialModal_gUtJFH4RA4 from "/home/web2wire/clifton-bootcamp/plugins/openTrialModal.ts";
import routeListener_client_a1kdH71wNi from "/home/web2wire/clifton-bootcamp/plugins/routeListener.client.ts";
import slideIn_client_5NQnabLgmi from "/home/web2wire/clifton-bootcamp/plugins/slideIn.client.ts";
import defaults_aRl2YLnTcb from "/home/web2wire/clifton-bootcamp/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/defaults.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_MwZUzHrRNP,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_client_UYiXMU8ZyN,
  sentry_client_VdOeuw25Mb,
  sentry_client_config_o34nk2sJbg,
  primevue_plugin_egKpok8Auk,
  plugin_client_XxbS7tGyhp,
  titles_dw2T9lEw4h,
  siteConfig_izaWKZ8rEu,
  inferSeoMetaPlugin_Uk9bcXDHeN,
  _00_logRocket_client_yE2ocTW3Tj,
  fadeIn_client_KC7KVKNaZA,
  globalComponents_client_lPWLC3zPcW,
  openTrialModal_gUtJFH4RA4,
  routeListener_client_a1kdH71wNi,
  slideIn_client_5NQnabLgmi,
  defaults_aRl2YLnTcb
]