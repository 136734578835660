<template>
  <section :class="classNames(props.class,{'w-full': props.full, 'mx-auto max-w-7xl': !props.full}, 'text-center pb-12',{ 'text-white': !props.inverted, 'bg-cbc-green text-black': props.inverted })">
    <slot />
  </section>
</template>

<script setup lang="ts">
  import classNames from "classnames"

  const props = withDefaults( defineProps<{
    inverted?: boolean
    full?: boolean
    class?: string
  }>(),{
    inverted: false,
    full: false,
    class: "",
  } )
</script>
