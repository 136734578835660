import { default as aboutDeCiG5tzSKMeta } from "/home/web2wire/clifton-bootcamp/pages/about.vue?macro=true";
import { default as classes6Wjfjmnt4eMeta } from "/home/web2wire/clifton-bootcamp/pages/classes.vue?macro=true";
import { default as contactwVtO57YhaaMeta } from "/home/web2wire/clifton-bootcamp/pages/contact.vue?macro=true";
import { default as indexxzjPPh3yHNMeta } from "/home/web2wire/clifton-bootcamp/pages/index.vue?macro=true";
import { default as pricingABd1Dp1WulMeta } from "/home/web2wire/clifton-bootcamp/pages/pricing.vue?macro=true";
import { default as privacy_45policyQ9xZ8rstDQMeta } from "/home/web2wire/clifton-bootcamp/pages/privacy-policy.vue?macro=true";
import { default as terms_45and_45conditionsHwxE1nNQKrMeta } from "/home/web2wire/clifton-bootcamp/pages/terms-and-conditions.vue?macro=true";
import { default as timetable53Ek9pCthKMeta } from "/home/web2wire/clifton-bootcamp/pages/timetable.vue?macro=true";
import { default as component_45stubSZt8hRT92nMeta } from "/home/web2wire/clifton-bootcamp/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubSZt8hRT92n } from "/home/web2wire/clifton-bootcamp/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "about",
    path: "/about",
    component: () => import("/home/web2wire/clifton-bootcamp/pages/about.vue")
  },
  {
    name: "classes",
    path: "/classes",
    component: () => import("/home/web2wire/clifton-bootcamp/pages/classes.vue")
  },
  {
    name: "contact",
    path: "/contact",
    component: () => import("/home/web2wire/clifton-bootcamp/pages/contact.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/web2wire/clifton-bootcamp/pages/index.vue")
  },
  {
    name: "pricing",
    path: "/pricing",
    component: () => import("/home/web2wire/clifton-bootcamp/pages/pricing.vue")
  },
  {
    name: "privacy-policy",
    path: "/privacy-policy",
    component: () => import("/home/web2wire/clifton-bootcamp/pages/privacy-policy.vue")
  },
  {
    name: "terms-and-conditions",
    path: "/terms-and-conditions",
    component: () => import("/home/web2wire/clifton-bootcamp/pages/terms-and-conditions.vue")
  },
  {
    name: "timetable",
    path: "/timetable",
    component: () => import("/home/web2wire/clifton-bootcamp/pages/timetable.vue")
  },
  {
    name: component_45stubSZt8hRT92nMeta?.name,
    path: "/lander",
    component: component_45stubSZt8hRT92n
  }
]